/* Default: desktop view*/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');

/* * { border: 1px solid red; } */

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

.navbar-div {
    font-family: Barlow Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2.3625px;

    position: fixed;
    margin-top: 40px;
    right: 0px;
    width: 85vw;
    width: 100%;
    height: 80px;
}

.icon-menu {
    display: none;
}

.line {
    position: relative;
    display: inline;
    margin-left: 100px;
}

.line > hr {
    color:thistle;
    position: relative;
    top: -30px;
    left: 10vw;
    opacity: 20%;
    width: 34vw;
}
#logo {
    margin-top: 20px;
    margin-left: 20px;
}
.navbar-background {
    z-index: -1;
    position: absolute;
    left: 40vw;
    top: -5px;
    width: 60vw;
    height: 90px;
    background: rgba(255, 255, 255, 0.04);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(81.5485px);
}

.page-name {
    padding-left: 15px;
}

.nav-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}

@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}

.nav-item:hover {
    border-bottom: 3px solid rgba(255, 255, 255, .5);;

}

.active-page {
    border-bottom: 3px solid white;
}

.pages-wrapper {
    position: absolute;
    top: -5px;
    right: 10vw;
    display: flex;
    gap: 40px;
    justify-content: flex-end;
    align-items: center;
    height: 90px;
    color: white;
}

.logo{
    width: 100px;
    height: auto;
}

@media(max-width:1300px) {

    .navbar-background {
        left: 30vw;
        width: 70vw;
    }
}

/* Tablet view */
@media(max-width:1110px) {
    .line > hr {
        display: none;
    }
    .pages-wrapper {
        position: absolute;
        top: -5px;
        display: flex;
        right: 10px;
        gap: 20px;
        justify-content: flex-end;
        align-items: center;
        height: 90px;
        color: white;
    }
    .logo{
        width: 80px;
        height: auto;
    }

    .navbar-background {
        left: 30vw;
        width: 70vw;
    }
}

@media(max-width:800px) {
    .navbar-div {
        margin-top: -11vh;
        padding: 0px;
        position: relative;
        top: 15vh;
    }

    .navbar-background {
        top: 0vh;
        z-index: 0;
    }

    .logo{
        width: 50px;
        height: auto;
    }
}

.hidden {
    display: none;
}

.mobile-menu {   
    position: fixed;
    color: white;
    backdrop-filter: blur(50px);
    z-index: 2;
    right: 0;
    top: 0px;
    width: 60%;
    height: 100vh;
}

.mobile-pages-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    margin-left: 10vw;
    gap: 30px;
}

@media(max-width:720px) {
    .navbar-background {
        left: 20vw;
        width: 80vw;
    }
}

/* Phone view */
@media(max-width: 520px) {
    .navbar-background, .pages-wrapper {
        display: none
    }

    .icon-menu, .close-menu {
        display: block;
        position: fixed;
        top: 5vh;
        right: 10vw;
    }
}
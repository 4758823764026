:root {
    --desktop-destination-image: url('../assets/prod/background-image1-desktop.jpg');
    --tablet-destination-image: url('../assets/prod/background-image1-tablet.jpg');
    --mobile-destination-image: url('../assets/prod/background-image1-mobile.jpg');
}

/* * { border: 1px solid red; }  */

* {
    margin: 0px;
    padding: 0px;
    max-width: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
}


.destination {
    background: var(--desktop-destination-image) no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100%;
    height: 100vh;
    margin: 0px;
    width: 100vw;
    color: white;
    bottom:0px;
}

.page-title {
    position: absolute;
    top: 23vh;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    margin-left: 168px;
}

.page-number {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin-right: 28px;
    opacity: 0.25;
}

.destination-info {
    position: absolute;
    right: 10vw;
    bottom: 10vh;
    width: 445px;
    height: 472px;
}

.destination-nav {
    text-transform: uppercase;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    display: flex;
    gap: 35px;
    height: 40px;
}

.active-destination {
    border-bottom: 3px solid white;
}

.nav-item-destination {
    display: flex;
    align-items: center;
    height: 100%;
}

.destination-name {
    text-transform: uppercase;
    display: inline-block;
    margin-top: 20px;
    font-family: Bellefair;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 115px;
}

.destination-description {
    margin-top: 20px;
    margin-bottom: 50px;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
}

.dest-line { 
    background-color: #383B4B; 
    height: 2px; 
    border: 0; 
}

.destination-time {
    text-transform: uppercase;
    margin-top: 20px;
    display: flex;
    gap: 80px;
}

.destination-time-title {
    font-family: Barlow Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2.3625px;
}

.destination-time-info {
    margin-top: 10px;
    font-family: Bellefair;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
}

.destination-image {
    animation: float 4s ease-in-out infinite;
    position: absolute;
    left: 10vw;
    top: 35vh;
    width: 30vw;
    height: auto;
}

@media(max-width:1500px) {
    
    .destination-name {
        font-size: 50px;
    }

    .destination-info{
        right: 10vw;
        bottom: 0vh;
    }
}




@media(max-width:1020px) {

    .destination {
        position: fixed;
        z-index: -1;
        overflow: scroll;
        height: 100%;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        gap: 50px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: var(--tablet-destination-image);
        background-position:center ;
        background-size: cover;
    }

    .destination-info {
        position: static;
        margin-bottom: 5vh;
        text-align: center;
    }

    .page-title {
        padding-top: 30vh;
        align-self: flex-start;
    }

    .content.dest {
        display: flex;
        flex-direction: column;
    }
    
    .img-wrapper  {
        display: flex;
        justify-content: center;
    }

    .destination-image {
        top: -5vh;
        position: relative;
        display: block;
        margin-right: 20vw;
    }

    .page-title {
        position: static;
    }

    .destination-nav,.destination-time {
        justify-content: center;
    }
}

@media(max-width:500px) {

    .page-title, .page-number {
        margin-left: 15vw;
        font-size: 20px;
    }

    .page-title {
        top: 8vh;
    }

    .destination {
        width: 100vw;
        overflow: overlay;
    }

    .destination-name {
        font-size: 56px;
    }

    .destination-time {
        flex-direction: column;
        gap: 50px;
    }

    .destination-time-wrapper {
        margin-top: 40px;
    }

    .destination-info {
        padding-left: 5vw;
        padding-right: 5vw;
        font-size: 14px;
        padding-bottom: 40px;
    }

    .test {
        clear: both;
    }

    .destination-time, .destination-name {
        margin-top: 0px;
    }

    .destination-time-wrapper {
        position: relative;
        bottom: 0px;
        display: block;
    }

    .destination-description {
        margin-top: 10px;
        margin-bottom: 10px;
    }

}